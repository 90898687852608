
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      components: {Header, Footer},
      name: 'AboutUsPage',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      data: function() {
        return {
          scrollPosition: 0,
          screenWidth: 0,
          quoteBlock: '',
          quoteBlockStyle: {},
          pquoteBlock: '',
          pquoteBlockStyle: {}
        };
      },
      beforeMount() {
        this.quoteBlock = 'quoteBlock wow animate__animated animate__fadeInUpBig';
        this.quoteBlockStyle = {'visibility': 'hidden', 'animation-name': 'none'};
        this.pquoteBlock = 'wow animate__animated animate__fadeIn';
        this.pquoteBlockStyle = {
          'visibility': 'hidden',
          'animation-delay': '1s',
          'animation-name': 'none'
        };
      },
      mounted() {
        this.quoteBlock = 'quoteBlock wow animate__animated animate__fadeInUpBig animate__animated';
        this.quoteBlockStyle = {'visibility': 'visible', 'animation-name': 'fadeInUpBig'};
        this.pquoteBlock = 'wow animate__animated animate__fadeIn animate__animated';
        this.pquoteBlockStyle = {
          'visibility': 'visible',
          'animation-delay': '1s',
          'animation-name': 'fadeIn'
        };
        setTimeout(() => {
          this.quoteBlock = 'quoteBlock wow  animate__fadeInUpBig animate__animated';
          this.pquoteBlock = 'wow  animate__fadeIn';
        }, 2000);
      }
    }
);
